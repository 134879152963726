.full-width {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: 768px) {
    margin-left: calc(-50vw + 360px); // half of 720 (750px - 30px of padding)
    margin-right: calc(-50vw + 360px); // half of 720 (750px - 30px of padding)
    padding-left: calc(50vw - 360px);
    padding-right: calc(50vw - 360px);
  }

  @media (min-width: 992px) {
    margin-left: calc(-50vw + 470px); // half of 940 (970px - 30px of padding)
    margin-right: calc(-50vw + 470px); // half of 940 (970px - 30px of padding)
    padding-left: calc(50vw - 470px);
    padding-right: calc(50vw - 470px);
  }

  @media (min-width: 1200px) {
    margin-left: calc(-50vw + 570px); // half of 1140 (1170px - 30px of padding)
    margin-right: calc(-50vw + 570px); // half of 11400 (1170px - 30px of padding)
    padding-left: calc(50vw - 570px);
    padding-right: calc(50vw - 570px);
  }
}

.hidden-nav {
  @include bp-nav {
    display: none;
  }
  
  &-max {
    @include bp-nav-max {
      display: none;
    }
    
  }
}


/*
|--------------------------------------------------------------------------
| Spacing
|--------------------------------------------------------------------------
*/
.mb{
  &-auto {
    margin-bottom: auto !important;
  }
  &-0 {
    margin-bottom: 0 !important;
  }
  &-0_5 {
    margin-bottom: 0.5rem !important;
  }
  &-1 {
    margin-bottom: 1rem !important;
  }
  &-1_5 {
    margin-bottom: 1.5rem !important;
  }
  &-2 {
    margin-bottom: 2rem;
  }
  &-3 {
    margin-bottom: 3rem !important;
  }
  &-4 {
    margin-bottom: 4rem !important;
  }
  &-5 {
    margin-bottom: 5rem !important;
  }
  &-6 {
    margin-bottom: 6rem !important;
  }
}

.ml {
  &-auto {
    margin-left: auto !important;
  }
  &-0 {
    margin-left: 0 !important;
  }
  &-0_5 {
    margin-left: .5rem !important;
  }
  &-1 {
    margin-left: 1rem !important;
  }
  &-1_5 {
    margin-left: 1.5rem !important;
  }
  &-2 {
    margin-left: 2rem !important;
  }
  &-3 {
    margin-left: 3rem !important;
  }
  &-4 {
    margin-left: 4rem !important;
  }
  &-5 {
    margin-left: 5rem !important;
  }
  &-6 {
    margin-left: 6rem !important;
  }
}
.mr {
  &-auto {
    margin-right: auto !important;
  }
  &-0 {
    margin-right: 0 !important;
  }
  &-0_5 {
    margin-right: .5rem !important;
  }
  &-1 {
    margin-right: 1rem !important;
  }
  &-1_5 {
    margin-right: 1.5rem !important;
  }
  &-2 {
    margin-right: 2rem !important;
  }
  &-3 {
    margin-right: 3rem !important;
  }
  &-4 {
    margin-right: 4rem !important;
  }
  &-5 {
    margin-right: 5rem !important;
  }
  &-6 {
    margin-right: 6rem !important;
  }
}

.my {

  &-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  &-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  &-0_5 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }
  &-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  &-1_5 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  &-2 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  &-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  &-4 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  &-5 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  &-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
}
.mx {

  &0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  &-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.mt {
  &-auto {
    margin-top: auto !important;
  }
  &-0 {
    margin-top: 0 !important;
  }
  &-0_5 {
    margin-top: .5rem !important;
  }
  &-1 {
    margin-top: 1rem !important;
  }
  &-1_5 {
    margin-top: 1.5rem !important;
  }
  &-2 {
    margin-top: 2rem !important;
  }
  &-3 {
    margin-top: 3rem !important;
  }
  &-4 {
    margin-top: 4rem !important;
  }
  &-5 {
    margin-top: 5rem !important;
  }
  &-6 {
    margin-top: 6rem !important;
  }
}

.p {
  &-0 {
    padding: 0 !important;
  }
  &-0_5 {
    padding: .5rem !important;
  }
  &-1 {
    padding: 1rem !important;
  }
  &-1_5 {
    padding: 1.5rem !important;
  }
  &-2 {
    padding: 2rem !important;
  }
  &-3 {
    padding: 3rem !important;
  }
  &-4 {
    padding: 4rem !important;
  }
  &-5 {
    padding: 5rem !important;
  }
  &-6 {
    padding: 6rem !important;
  }
}

.pr {
  &-0 {
    padding-right: 0 !important;
  }
  &-0_5 {
    padding-right: .5rem !important;
  }
  &-1 {
    padding-right: 1rem !important;
  }
  &-1_5 {
    padding-right: 1.5rem !important;
  }
  &-2 {
    padding-right: 2rem !important;
  }
  &-3 {
    padding-right: 3rem !important;
  }
  &-4 {
    padding-right: 4rem !important;
  }
  &-5 {
    padding-right: 5rem !important;
  }
  &-6 {
    padding-right: 6rem !important;
  }
}

.pl {
  &-0 {
    padding-left: 0 !important;
  }
  &-0_5 {
    padding-left: .5rem !important;
  }
  &-1 {
    padding-left: 1rem !important;
  }
  &-1_5 {
    padding-left: 1.5rem !important;
  }
  &-2 {
    padding-left: 2rem !important;
  }
  &-3 {
    padding-left: 3rem !important;
  }
  &-4 {
    padding-left: 4rem !important;
  }
  &-5 {
    padding-left: 5rem !important;
  }
  &-6 {
    padding-left: 6rem !important;
  }
}

.px {
  &-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  &-1_5 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}

.pb{
  &-0 {
    padding-bottom: 0 !important;
  }
  &-0_5 {
    padding-bottom: .5rem !important;
  }
  &-1 {
    padding-bottom: 1rem !important;
  }
  &-1_5 {
    padding-bottom: 1.5rem !important;
  }
  &-2 {
    padding-bottom: 2rem !important;
  }
  &-3 {
    padding-bottom: 3rem !important;
  }
  &-4 {
    padding-bottom: 4rem !important;
  }
  &-5 {
    padding-bottom: 5rem !important;
  }
  &-6 {
    padding-bottom: 6rem !important;
  }
}

.pt {
  &-0 {
    padding-top: 0 !important;
  }
  &-0_5 {
    padding-top: .5rem !important;
  }
  &-1 {
    padding-top: 1rem !important;
  }
  &-1_5 {
    padding-top: 1.5rem !important;
  }
  &-2 {
    padding-top: 2rem !important;
  }
  &-3 {
    padding-top: 3rem !important;
  }
  &-4 {
    padding-top: 4rem !important;
  }
  &-5 {
    padding-top: 5rem !important;
  }
  &-6 {
    padding-top: 6rem !important;
  }
}




/*
|--------------------------------------------------------------------------
| Colors
|--------------------------------------------------------------------------
*/
.bg {
  &-gray-300 {
    background: $gray-300 !important;
  }
  &-gray-400 {
    background: $gray-400 !important;
  }
  &-gray-500 {
    background: $gray-500 !important;
  }
  &-gray-600 {
    background: $gray-600 !important;
  }
  &-transparent {
    background: transparent !important;
  }
}

.text {
  &-gray-300 {
    color: $gray-300 !important;
  }
  &-gray-400 {
    color: $gray-400 !important;
  }
  &-gray-500 {
    color: $gray-500 !important;
  }
  &-gray-600 {
    color: $gray-600 !important;
  }
  &-primary {
    color: $brand-primary !important;
  }


  &-sm {
    font-size: 1.4rem;
  }
}

/*
|--------------------------------------------------------------------------
| Borders
|--------------------------------------------------------------------------
*/
.border {
  &-none {
    border: none !important;
  }
}

/*
|--------------------------------------------------------------------------
| Display
|--------------------------------------------------------------------------
*/
.d {
  &-inline {
    display: inline !important;
  }
  &-inline-block {
    display: inline-block !important;
  }
  &-block {
    display: block !important;
  }
  &-inline-flex {
    display: inline-flex !important;
  }
  &-flex {
    display: flex !important;

    &:before, &:after {
      display: none;
    }
  }
  &-none {
    display: none;
  }
}

.align-items {
  &-center {
    align-items: center;
  }
}

.justify-content {
  &-center {
    justify-content: center;
  }
  &-around {
    justify-content: space-around;
  }
  &-between {
    justify-content: space-between;
  }
  &-end {
    justify-content: flex-end;
  }
}

.flex {
  &-column {
    flex-direction: column;
  }
  &-wrap {
    flex-wrap: wrap;
  }
  &-nowrap {
    flex-wrap: nowrap;
  }
}



/*
|--------------------------------------------------------------------------
| Icons
|--------------------------------------------------------------------------
*/
.maticon, .icon {
  &-lg {
    font-size: 3.6rem !important;
  }
  &-sm {
    font-size: 1.6rem !important;
  }
}

/*
|--------------------------------------------------------------------------
| Flex
|--------------------------------------------------------------------------
*/
.d-flex {
  display: flex !important;
}

.d-sm-flex {
  @media (min-width: 576px) {
    display: flex !important;
  }
}

.d-md-flex {
  @media (min-width: 768px) {
    display: flex !important;
  }
}

.d-lg-flex {
  @media (min-width: 992px) {
    display: flex !important;
  }
}

.d-xl-flex {
  @media (min-width: 1200px) {
    display: flex !important;
  }
}
