.node-webform {
    .field-name-body {
        margin-bottom: 3rem;
    }

    form > div {
        display: flex;
        flex-wrap: wrap;
        @extend .row;

        &:before, &:after {
            display: none;
        }

        > div, > .form-wrapper{
            @include bp-xs-max {
                width: 100%;
            }

            &:not([class*=col]) {
                @extend .col-sm-12;
            }
        }

        .form-managed-file {
            display: flex;

            &:before, &:after {
                display: none;
            }

            .form-control {
                height: auto;
            }
        }
    }
}

.captcha.panel {
    border: none;

    .panel-body {
        border: none !important;
    }
}