.view-products {
    .row {
        display: flex;
        flex-wrap: wrap;

        &:before, &:after {
            display: none;
        }
    }

    .item {
        > a {
            @extend .btn;
            @extend .btn-primary;
        }
    }
}

// Product Slider
.photoswipe-gallery .field-name-uc-product-image .field-items {
    margin-bottom: 15px;
    .slick-slide {
        height: 0;

        &.slick-current {
            height: 100%;
        }
    }

}

// Product Slider Nav
.product-slick-nav {
    .slick-list {
        padding: 0 !important;
    }

    .slick-track {
        width: 100% !important;
        transform: none !important;
        display: flex;
        flex-wrap: wrap;

        &:before, &:after {
            display: none;
        }

        img {
            width: calc(20% - 12px) !important;
            position: static !important;
            opacity: 1 !important;
            display: block;
            margin-right: 15px;
            margin-bottom: 15px;

            &:nth-child(5n) {
                margin-right: 0;
            }
        }
    }

    .slick-cloned {
        display: none !important;
    }
}

.node-add-to-cart {
    @extend .btn-primary;
}