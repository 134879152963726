.uc-credit-cctype, .uc-credit-cvv-help img, .uc-order-action img {
    display: inline;
}

#payment-details {
    > div {
        clear: both;
    }
}

.ds-3col-stacked-equal {
    @include bp-xs-max {
        display: flex;
        flex-direction: column;
        &:before, &:after {
            display: none;
        }
        > div {
            width: 100% !important;
            margin-bottom: 1rem;
        }
    }

}

body.page-cart  {
    .glyphicon {
        display: none;
    }
    #uc-cart-view-form {
        .table-responsive {
            border: none;
        }

        @include bp-sm-max {
            thead {
                display: none;
            }
        }

        table {
            margin-bottom: 0;
        }

        tr {
            background: #FFF;
            border-bottom: solid 1px $gray-400;

            &:last-child {
                padding-bottom: 0;
            }

            @include bp-sm-max {
                padding-bottom: 1.5rem;
                margin-bottom: 1.5rem;
                display: flex;
                flex-wrap: wrap;
                //border: none;

                &:before, &:after {
                    display: none;
                }
            }


            td {
                border: none;
                vertical-align: middle;

                @include bp-sm-max {
                    width: 100%;
                }

                &.remove {
                    min-width: 10rem;

                    @include bp-sm-max {
                        order: 2;
                        width: 12rem;
                    }

                    button {
                        font-size: 1.6rem;
                        background: transparent;
                        border: none;
                        padding: 0;
                        color: $text-color;
                        min-width: 9rem;

                        &:before {
                            content: '\f057';
                            font-size: 1.6rem;
                            font-family: 'Font Awesome\ 5 Free';
                            font-weight: 900;
                            color: $brand-danger;
                            margin-left: -5px;
                            line-height: 1;
                        }
                    }
                }

                &.qty {
                    width: calc(100% - 20rem);
                    order: 3;

                    > div {
                        display: flex;
                        justify-content: flex-end;
                        margin-bottom: 0;

                        &:before, &:after {
                            display: none;
                        }
                    }

                    input {
                        max-width: 60px;
                    }
                }

                &.price {
                    width: 8rem;
                }

                &.desc {
                    //font-family: $font-family-base-condensed;
                    font-size: 1.4rem;

                    > a {
                        font-weight: 700;
                        text-transform: uppercase;
                        font-size: 1.6rem;
                    }
                }

                @include bp-sm-max {

                    &.desc, &.image {
                        //border-bottom: solid 1px $gray-300;
                    }

                    &.desc {
                        order: -1;
                        width: calc(100% - 66px);
                        display: flex;
                        align-items: center;
                        white-space: normal;

                        &:before, &:after {
                            display: none;
                        }

                    }

                    &.image {
                        order: -2;
                        width: 66px;

                        img {
                            padding: 0 !important;
                            max-width: 50px;
                            max-height: 50px;
                        }
                    }

                    &.price {
                        order: 4;
                    }
                }
            }
        }

        .form-actions {
            margin-top: 1.5rem;

            @include bp-xs-max {
                margin-top: 0;
                display: flex;
                flex-direction: column;

                &:before, &:after {
                    display: none;
                }

                button {
                    margin: 0 0 1.5rem;
                }
            }
        }

        #edit-update {
            &:before {
                content: "\f2f1";
                font-family: 'Font Awesome\ 5 Free';
                font-weight: 300;
                margin-right: 1rem;
                font-size: 1.4rem;
            }
        }

        #edit-empty {
            @extend .btn-danger;
        }

        .form-actions a {
            margin-left: 0;
            order: 5;

            &:before {
                content: "\f0d9";
                font-family: 'Font Awesome\ 5 Free';
                font-weight: 300;
                margin-right: 1rem;
            }
        }
    }


    #uc-cart-checkout-form {
        .table-striped {
            tr {
                background: transparent;
            }
        }

        .form-actions {
            display: flex;
            justify-content: space-between;
            margin-top: 1.5rem;

            &:before, &:after {
                display: none;
            }

        }

        #edit-cancel {
            @extend .btn-danger;
        }

        #edit-continue {
            @extend .btn-primary;
        }

        button[value='Click to calculate shipping'] {
            @extend .btn-info;
        }


    }
}