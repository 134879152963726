/*
|--------------------------------------------------------------------------
| CTA Block
|--------------------------------------------------------------------------
*/
.cta-block {
    &--diagonal .row {
        display: flex;
        flex-wrap: wrap;

        &:before, &:after {
            display: none;
        }
        
        .item {
            background-size: cover;
            background-repeat: no-repeat;
            text-align: center;
            padding: 6rem;
            color: white;
            position: relative;
            overflow: hidden;

            @include bp-md-max {
                width: 100%;
            }


            &:before {
                content: "";
                position: absolute;
                bottom: -100%;
                right: -155%;
                width: 200%;
                height: 350%;
                display: block;
                transform: skewX(-45deg);
            }

            &.diagonal-primary {
                &::before {
                    background: rgba($brand-primary, .8);
                }

            }

            &.diagonal-secondary {
                &::before {
                    background: rgba($brand-secondary, .8);
                }
            }

            &.diagonal-tertiary {
                &::before {
                    background: rgba($brand-tertiary, .8);
                }
            }

            &.diagonal-dark {
                &::before {
                    background: rgba(57, 57, 57, 0.8);
                }
            }

            .caption {
                position: relative;
                z-index: 30;
                max-width: 36rem;
                margin: 0 auto;
            }


            img {
                margin: 0 auto 4rem;
                position: relative;
                z-index: 30;
                max-height: 400px;

            }

            p {
                margin-bottom: 1.5rem;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| Instagram Feed
|--------------------------------------------------------------------------
*/
.insta-feed {
    text-align: center;
    color: $gray-600;
    padding-top: 3rem;

    .fa {
        font-size: 4rem;
        color: $gray-400;
    }

    .insta-wrap {
        display: flex;
        flex-wrap: wrap;
        margin-top: 1rem;

        &:before, &:after {
            display: none;
        }

        .item {
            width: 50%;
            display: flex;
            align-items: flex-end;

            &:before, &:after {
                display: none;
            }
            

            @include bp-md {
                width: 33.333%;
            }

            @include bp-lg {
                width: 16.666666666666667%;
            }

            img {
                object-fit: cover;
                height: 100%;
                width: 100%;
                height: calc(100vw / 2);

                @include bp-md {
                    height: calc(100vw / 3);
                }

                @include bp-lg {
                    height: calc(100vw / 6.033);
                }
            }
            
            
        }
    }

}