.captcha {
    box-shadow: none;

    .panel-heading {
        display: none;
    }

    .panel-body {
        padding: 0;
        border: 0;
        display: flex;
        flex-direction: column;
        &:before, &:after {
            display: none;
        }

        .help-block {
            order: 9;
        }

    }
}

#user-pass {
    .btn-default {
    }
}

.captcha.panel {
    border: none;
}

.admin-tabs {
    margin-bottom: 1.5rem;
}