/*===========================
    # Layout
 ===========================*/
html {
    height: 100%; // used to make footer always be at bottom (for short pages)
    overflow-x: hidden;
    font-size: 62.5%;
}

body {
    min-height: 100%;
    overflow-x: hidden;
    // used to make the footer always be at bottom (for short pages)
    display: flex;
    flex-direction: column;
}

.main-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
    //overflow-x: hidden;
    max-width: 100%;
}