/*
|--------------------------------------------------------------------------
| Accordion Panels
|--------------------------------------------------------------------------
*/
.panel {
    border: none;
    box-shadow: none;

    .panel-heading  {
        padding: 0;
        background: $gray-300;
        border: none;
        margin: 0;
    }

    .panel-title {
        padding: 1rem;
        font-weight: $font-weight-regular;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:before, &:after {
            display: none;
        }

    }



    .accordion-toggle {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;

        &:before, &:after {
            display: none;
        }
        
        &:before {
            display: none;
        }

        &:after {
            content: "\e5cf";
            font-family: "Material Icons";
            font-size: 3.6rem;
            line-height: 1rem;
            display: block;
        }
    }

    .panel-body {
        border: solid 1px $gray-300 !important;
    }
}