.footer {
    margin-top: auto;

    .region-footer {
        background: $gray-700;
        color: white;
        @extend .full-width;
        padding-top: 3rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @include bp-md {
            justify-content: space-between;
        }


        &:before, &:after {
            display: none;
        }

        .footer--left, .footer--right {
            padding: 0 1.5rem;
            margin-bottom: 3rem;
        }



        .footer--right {
            text-align: right;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }
}