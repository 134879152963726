 .block-gm-builder #root > .container {
    //padding: 0 !important;
    //@extend .full-width;
    //
    //> .row{
    //    margin: 0;
    //}
}

.tgl {
    outline: 0;
    display: block;
    width: 4em;
    height: 2em;
    position: relative;
    cursor: pointer;
    user-select: none;

    &:after,
    &:before {
        position: relative;
        display: block;
        content: "";
        width: 50%;
        height: 100%;
    }

    &:after {
        left: 0;
    }

    &:before {
        display: none;
    }

    &.is-active:after {
        left: 50%;
    }
}

// themes
.tgl-default {
    background: #f0f0f0;
    border-radius: 2em;
    padding: 2px;
    transition: all .4s ease;
    &:after {
        border-radius: 50%;
        background: #fff;
        transition: all .2s ease;
    }

    &.is-active {
        background: #9FD6AE;
    }
}

$bp--desktop: 992px;
/*
|--------------------------------------------------------------------------
| Base
|--------------------------------------------------------------------------
*/
body.page-node-17, body.page-node-48, body.page-node-9983, body.page-node-20799 {
    background: $gray-300;

    //button {
    //    background: transparent;
    //}
    //
    //.btn {
    //    border: none;
    //}
}


.app-page-header {
    .right {
        min-width: 146px;
        margin-left: auto;
    }
    h4 {
        font-size: 2.2rem;

        @include bp-lg {
            font-size: 3.2rem;
        }
    }
}
/*
|--------------------------------------------------------------------------
| Toggle Wrap
|--------------------------------------------------------------------------
*/
.toggle-wrap {
    display: flex;
    align-items: center;
    margin-bottom: .8em;

    &:before, &:after {
        display: none;
    }

    button {
        margin-left: 1.5rem;
    }
}

/*
|--------------------------------------------------------------------------
| Flickity / Canvas Carousel
|--------------------------------------------------------------------------
*/
.flickity-slider > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*
|--------------------------------------------------------------------------
| Piece Selector
|--------------------------------------------------------------------------
*/
.piece-selector-wrap .scrollable-overflow__content {
    @include bp-lg {
        overflow-y: scroll;
    }
}

.piece-selector {
    margin: 0;
    display: flex;

    &:before, &:after {
        display: none;
    }

    @include bp-lg {
        display: block;
        flex-direction: column;
        max-height: 50vh;
        padding-top: 0;
        padding-left: 1rem;
    }

    > div {
        flex: 1;
        border-bottom: solid 6px $brand-secondary;

        @include bp-lg {
            border-bottom: none;
        }
        
    }
}

.piece-selector__piece {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    white-space: normal;
    padding: 2rem 4rem;
    width: 100%;
    min-height: 140px;

    @include bp-lg {
        //margin-bottom: 2rem;
    }


    &.is-active {
        background: #fff !important;

        @include bp-lg {
            //border-left: solid 4px $brand-secondary;
            //border-top: none;
        }

        &:before {
            content: "";
            width: 0;
            height: 0;
            border-bottom: 10px solid transparent;  /* left arrow slant */
            border-top: 10px solid transparent; /* right arrow slant */
            border-left: 10px solid $brand-secondary; /* bottom, add background color here */
            font-size: 0;
            line-height: 0;
            position: absolute;
            transform: translateX(-50%) rotate(-90deg);
            left: 50%;
            bottom: -6px;


            @include bp-lg {
                //transform: translateY(50%);
                //bottom: 50%;
                //left: 0;

                display: none;
            }

        }
    }

    & .color-indicator {
        width: 25px;
        height: 25px;
        margin-top: .2em;
        margin-right: .2em;
        position: absolute;
        top: 0;
        right: 0;
    }

}

/*
|--------------------------------------------------------------------------
| Piece Navigation Controls
|--------------------------------------------------------------------------
*/
.piece-navigation-controls {
    button {
        display: flex;
        align-items: center;
        justify-content: center;

        &:before, &:after {
            display: none;
        }

        &:hover i, &:focus i {
            color: $gray-600 !important;
        }

    }
    i {
        transition: .2s all;
        line-height: .5;
    }
}

/*
|--------------------------------------------------------------------------
| Piece Options
|--------------------------------------------------------------------------
*/
.piece-options__color-btn {
    width: 30px;
    height: 30px;
    padding: 0;
    border-radius: 30px;
    box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.1);
    border: 0.1875em solid transparent;
    transition: .2s all;
}

.piece-options__color-btn.is-active {
    border: 0.1875em solid #fff;
    box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.5);
}

/*
|--------------------------------------------------------------------------
| Overflow
|--------------------------------------------------------------------------
*/
.scrollable-overflow {
    position: relative;
}

.scrollable-overflow__control {
    position: absolute;
    top: 0;
    height: calc(100% - 21px);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    transition: all 0.2s;
    border: none;
}

.scrollable-overflow__control {
    &:hover i, &:focus i {
        color: $gray-600;
    }
    .material-icons {
        font-size: 3.5rem;
        color: #858585;
    }


}

.scrollable-overflow__control--right {
    position: absolute;
    right: 0;
    margin-right: -0.2rem;
    background: linear-gradient(270deg, #f6f6f6 0%, rgba(255, 255, 255, 0) 100%);
    padding-left: 1em;
}

.scrollable-overflow__control--left {
    margin-left: -0.2rem;
    background: linear-gradient(-270deg, #f6f6f6 0%, rgba(255, 255, 255, 0) 100%);
    padding-right: 1em;
}

/*
|--------------------------------------------------------------------------
| Canvas
|--------------------------------------------------------------------------
*/
.canvas-carousel {
    #block-gm-builder-leg-guard-builder & .responsive-canvas-wrap {
        max-width: 220px !important;

        @media (min-width: 768px){
            max-width: 366px !important;
        }

        &--canvas--front {
            max-width: 130px !important;

            @media (min-width: 768px){
                max-width: 220px !important;
            }
        }
    }

    .flickity-button {
        svg {
            transition: .2s all;
        }
        &:hover svg, &:focus svg {
            fill: $gray-600;
        }
    }
}

.canvas-thumbs {
    display: none;
    @include bp-lg {
        display: flex;
    }

    &__item {

    }
}

/*
|--------------------------------------------------------------------------
| Color indicator
|--------------------------------------------------------------------------
*/
.color-indicator {
    border-radius: 30px;
    width: 30px;
    height: 30px;
    border: 0.1875em solid #fff;
    box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.5);
}

/*
|--------------------------------------------------------------------------
| Current Piece Card
|--------------------------------------------------------------------------
*/
.current-piece-card {
    > h5 {
        margin: 0;
        height: 5.6rem;
    }
}
.piece-navigation-controls {
    height: 5.6rem;

    @include bp-lg-max {
        display: none !important;
    }
    

    [aria-label='customize next piece']{
        margin-top: auto;
    }

    [aria-label='customize previous piece']{
        margin-bottom: auto;
    }

}


/*
|--------------------------------------------------------------------------
| MAIN
|--------------------------------------------------------------------------
*/

.app-main {
    @include bp-lg-max {
        width: 100%;
    }
        
    > .toggle-wrap {
        //padding: 0 15px;
    }
}

.app-container {
    display: flex;
    flex-wrap: wrap;
    &:before, &:after {
        display: none;
    }
    
}


/*
|--------------------------------------------------------------------------
| Sidebar
|--------------------------------------------------------------------------
*/
.app-sidebar {
    display: flex;
    flex-direction: column;

    &:before, &:after {
        display: none;
    }

    @include bp-lg-max {
        width: 100%;
    }

    @include bp-lg {
        .scrollable-overflow__controls {
            display: none;
        }
        .scrollable-overflow__content {
            overflow-x: initial !important;
        }
    }
    
    &__left, &__right {
        @include bp-xs-max {
            padding: 0;
        }
        
    }
    
    &__left {
        @include bp-lg {
            padding-right: 0;
        }

        > .scrollable-overflow {
            position: relative;

            @include bp-lg {
                margin-top: -6rem;

                //&:before, &:after {
                //    content: "";
                //    background: linear-gradient(to bottom, rgba($gray-300, 1) 15%, rgba($gray-300, 0) 90%);
                //    position: absolute;
                //    left: 0;
                //    right: 13px;
                //    z-index: 4;
                //    top: 0;
                //    bottom: calc(100% - 8rem);
                //}
                //
                //&:after {
                //    background: linear-gradient(to top, rgba($gray-300, 1) 15%, rgba($gray-300, 0) 90%);
                //    bottom: 0;
                //    top: calc(100% - 8rem);
                //}
            }
            

            &__content {
                padding-top: 3rem;
                padding-bottom: 3rem;
            }
        }
    }

    &__right {
        > div {
            background: white;
            margin-bottom: 1rem;
            box-shadow: 0 0 16px rgba(black, .2);
        }
    }
}


/*
|--------------------------------------------------------------------------
| Progress Bar
|--------------------------------------------------------------------------
*/

.progress-wrap {
    display: none;
    margin-top: auto;
    margin-bottom: 3rem;

    @include bp-lg {
        display: block;
    }

    .progress {
        border-radius: 0;
        background: white;
        box-shadow: 0 0 10px rgba(black, .1);
    }
    
}
