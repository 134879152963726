

/*
|--------------------------------------------------------------------------
| Site Header
|--------------------------------------------------------------------------
*/


.site-header {
    font-family: $headings-font-family;
    border: none;

    > .container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        &:before, &:after {
            display: none;
        }        
    }

    .utility-nav {
        margin-left: auto;
        margin-right: 2rem;

        @include bp-nav {
            margin-right: 0;
        }


        li.cart {
            position: relative;

            a {
                padding: 0 !important;
                margin: 0;

                span {
                    background: $brand-primary;
                    width: 2rem;
                    height: 2rem;
                    border-radius: 50%;
                    color: white;
                    font-size: 1.4rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: .3rem;
                    right: -.6rem;
                    line-height: 1;
                }

                &:after {
                    display: none;
                }

                &:before {
                    content: "\e8cc";
                    font-family: 'Material Icons';
                    font-size: 3rem;
                    color: $gray-500;
                    transition: .2s all;
                    position: relative;
                    bottom: -0.7rem;
                }

                &:hover, &:focus {
                    background: transparent;

                    &:before {
                        color: $gray-600;
                    }
                }

            }

        }
    }


    .menu {
        display: flex;
        text-transform: uppercase;
        flex-direction: column;

        @include bp-nav {
            flex-direction: row;
        }

        &:before, &:after {
            display: none;
        }        
    }
    
    .navbar-header {
        display: flex;
        justify-content: space-between;
        margin: 0;

        @include bp-nav-max {
            width: 100%;
        }

        &:before, &:after {
            display: none;
        }
    }

    .navbar-collapse {
        margin: 0;
        border: none;
        font-size: 1.8rem;
        width: 100%;

        @include bp-nav {
            width: calc(100% - 160px);
        }

        .menu {
            > li {
                @include bp-nav {
                    margin-left: 2rem;
                    margin-top: auto;
                    margin-bottom: auto;
                }

                &:first-child {
                    margin-left: 0;
                }

                > a:not(.btn) {
                    padding: 1rem 0;
                    border-bottom: solid 4px transparent;

                }
            }

            .open > a.btn-customizer {
                border-color: $gray-400;
            }
        }

        nav {
            display: flex;
            flex-wrap: wrap;
            
            &:before, &:after {
                display: none;
            }

            .navbar-nav {
                margin-left: 0;
                margin-right: 0;
            }
            
            > .menu {
                display: flex;
                margin-bottom: 2rem;
                width: 100%;

                &:before, &:after {
                    display: none;
                }

                @include bp-nav {
                    margin-bottom: 0;
                    width: auto;
                }

                > li > a.active {
                    border-bottom: solid 4px $brand-secondary;
                }
            }

            > div {
                width: 100%;
                margin-top: 1.5rem;
                border-bottom: solid 1px $gray-300;
                order: -1;

                @include bp-nav {
                    border: none;
                    width: auto;
                    margin-top: 0;
                    order: 4;
                }
                
            }
        }

    }
}

/*
|--------------------------------------------------------------------------
| Top Bar
|--------------------------------------------------------------------------
*/
.top-bar {
    display: none;

    @include bp-nav {
        display: block;
    }
    

    .region-header {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: .5rem 0;

        &:before, &:after {
            display: none;
        }
    }

    &--left, &--right {
        width: 100%;

        @include bp-sm {
            width: 50%;
        }
    }
    
    &--right {
        text-align: right;

        .menu {
            justify-content: flex-end;
        }
    }

    .menu {
        margin: 0 -.5rem;

        li {
            padding: 0;
            margin: 0 .5rem;
        }

        a {
            color: $gray-500;
            padding: .5rem;

            &:hover, &:focus {
                color: $gray-600;
            }
        }

        .fa {
            font-size: 2rem;
        }
    }
}

.navbar-toggle {
    margin: auto -10px auto !important;
}

.logo {
    background: $brand-primary;
    padding: 15px;
    max-width: 130px;
    display: block;
    margin-bottom: -1rem;
    position: relative;
    z-index: 9;
}