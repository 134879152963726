.btn {
  font-family: $headings-font-family;
  text-transform: uppercase;
  padding: 1rem 2rem;
  font-size: 1.8rem;

  &-default {
  }

  &-primary {

  }

  &-info {

  }

  &-success {

  }

  &-danger {

  }

  &-plain {
    background: transparent;
    border: none;
  }

  &-customizer {
    border: solid 2px $gray-400;
    padding: 1rem 2rem !important;

    &.active {
      border-bottom: solid 2px $gray-300 !important;
      box-shadow: none;
    }

    &:before {
      content: "";
      background: url(/sites/default/files/customize-icon.png) no-repeat;
      background-size: contain;
      width: 1.8rem;
      height: 1.8rem;
      margin-right: .5rem;
      display: inline-block !important;
    }

    &-light {
      background: white;
      color: $text-color;
      border: none;
    }
  }
}