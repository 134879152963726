/*
|--------------------------------------------------------------------------
| Cart
|--------------------------------------------------------------------------
*/

#cart-form-pane {
    #edit-checkout--2 {
        @extend .btn-primary;
    }

    .qty input {
        padding: 6px 0;
        text-align: center;
    }
}
