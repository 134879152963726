h1,
h2,
h3,
h4,
h5,
h6 {
    text-transform: uppercase;
    letter-spacing: 1px;
}



.lead {
    font-family: $headings-font-family;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: $font-weight-regular;
}

.breadcrumb {
    margin-bottom: 0;
    padding: 0;
    margin-top: 1.5rem;
}

.glyphicon {
    &-log-in:before {
        content: '\f2f6';
        font-family: "Font Awesome 5 Free";
        font-weight: 300;
    }
}