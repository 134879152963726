/*
|--------------------------------------------------------------------------
| Promoted Slides
|--------------------------------------------------------------------------
*/
.view-promoted-slides {
    color: white;

    a:not([class^='btn']) {
        color: white;
        text-decoration: none;

        &:hover, &:focus {
            color: $gray-400;
        }
    }

    .item {
        position: relative;

        > img {
            width: 100%;
        }
    }
    .caption {
        @extend .container;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;

        &:before, &:after {
            display: none;
        }

        .caption-contents {
            max-width: 80rem;
        }

        h2 {
            font-size: 5rem;
            margin-bottom: 1.5rem;

            &:before {
                content: '';
                height: 6px;
                width: 12rem;
                background: $brand-secondary;
                display: block;
                margin-bottom: 1.5rem;
            }
        }

        p {
            margin-bottom: 1.5rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

}