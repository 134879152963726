.flickity-slider {
    .item {
        width: 100%; /* full width */
        margin-right: 0;
    }
}

.flickity-button {
    background: transparent !important;

    &:hover, &:focus {
        svg {
            fill: $gray-300;
        }
    }
    svg {
        fill: $gray-500;
    }
}